import { useAppReduxSelector } from "hooks/reduxHooks";
import { TextEditor } from "react-data-grid";
import { useDispatch } from "react-redux";
import { CellNumberUpdateType } from "../../PoolingNormalizationToolHelpers";
import { PoolingNormalizationToolActions } from "../../state";
import { WellMapping } from "../WellMapping";
import { WellMappingTableHeader } from "../WellMappingTableHeader";

export const Int2ToInt3WellsMapping = () => {
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );
  const dispatch = useDispatch();
  const columns = [
    {
      key: "sourcePlateIndex",
      name: "Source Plate",
      formatter(props: any) {
        return <>{props.row.sourcePlateIndex + 1 ?? ""}</>;
      },
      resizable: true,
    },
    { key: "sourceWellId", name: "Source Pos", resizable: true },
    {
      key: "destPlateIndex",
      name: "Dest Plate",
      formatter(props: any) {
        return <>{props.row.destPlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "destWellId", name: "Dest Pos", resizable: true },
    {
      key: "transferVol",
      name: "Cell Number",
      resizable: true,
      editor: TextEditor,
    },
  ];
  const handleApplyCellNumber = (option: string, cellNumber: number) => {
    switch (option) {
      case "Apply to All":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_INT2_TO_INT3_CELL_NUMBERS({
            updateType: CellNumberUpdateType.ALL,
            transferVol: cellNumber,
          })
        );
        break;
      case "Apply to Empty":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_INT2_TO_INT3_CELL_NUMBERS({
            updateType: CellNumberUpdateType.EMPTY,
            transferVol: cellNumber,
          })
        );
        break;
    }
  };
  const setSingleCellNumber = (rows: unknown[]) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_INT2_TO_INT3_CELL_NUMBERS({
        updateType: CellNumberUpdateType.INDIVIDUAL,
        rows,
      })
    );
  };
  return (
    <WellMapping
      setSingleCellNumber={setSingleCellNumber}
      columns={columns}
      worklistValues={worklistValues.int2ToInt3}
      tableHeader={
        <WellMappingTableHeader handleApplyCellNumber={handleApplyCellNumber} />
      }
    />
  );
};
