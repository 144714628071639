import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { PoolingNormalizationToolInternalAction } from "../../state/action";
import { usePoolingNormalizationStateProvider } from "../../state/StateProvider";

const DragItem = styled.div`
  padding: 10px;
  width: 95%;
  border-radius: 6px;
  background: ${({ color, theme }) =>
    color ?? theme.palette.secondary.main} !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 8px;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
  color: white;
`;

export const SeedingPriority_96_384_LabwareTypes = () => {
  const {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  } = usePoolingNormalizationStateProvider();

  const seedingPriority_96_384_Well =
    poolingNormalizationToolInternalState.methodSettings
      .seedingPriority_96_384_Well;

  useEffect(() => {
    setSeedingPriority_96_384(
      seedingPriority_96_384_Well.map((item, index) => {
        return { id: item, content: item };
      })
    );
  }, [seedingPriority_96_384_Well]);

  const [seedingPriority_96_384, setSeedingPriority_96_384] = useState<
    { id: string; content: string }[]
  >([]);
  const onLabwareDragEnd_96_384 = (result: any) => {
    const newItems = Array.from(seedingPriority_96_384);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSeedingPriority_96_384(newItems);

    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_96_384_SEEDING_PRIORITY,
      payload: {
        seedingPriority: newItems.map((item) => item.content),
      },
    });
  };

  const hasMultiple96or384WellSeeds = seedingPriority_96_384_Well.length > 1;
  return (
    <DragDropContext onDragEnd={onLabwareDragEnd_96_384}>
      <Droppable droppableId="labwareDroppable">
        {(labwareDroppableProvided) => (
          <div
            {...labwareDroppableProvided.droppableProps}
            ref={labwareDroppableProvided.innerRef}
          >
            {seedingPriority_96_384.map((labwareItem, index) => (
              <Draggable
                key={labwareItem.id}
                draggableId={labwareItem.id}
                index={index}
                isDragDisabled={!hasMultiple96or384WellSeeds}
              >
                {(labwareProvided, snapshot) => (
                  <DragItem
                    ref={labwareProvided.innerRef}
                    {...labwareProvided.draggableProps}
                    {...labwareProvided.dragHandleProps}
                  >
                    {labwareItem.content}
                  </DragItem>
                )}
              </Draggable>
            ))}
            {labwareDroppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
