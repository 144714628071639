import { useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import { TextEditor } from "react-data-grid";
import { useDispatch } from "react-redux";
import { PoolingNormalizationToolActions } from "../../state";
import { WellMapping } from "../WellMapping";
import { WellMappingTableHeader } from "../WellMappingTableHeader";

export const DestinationWellMapping = () => {
  const dispatch = useDispatch();
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );

  const columns = [
    {
      key: "sourcePlateIndex",
      name: "Source Plate",
      formatter(props: any) {
        return <>{props.row.sourcePlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "sourceWellId", name: "Source Pos", resizable: true },
    {
      key: "destPlateIndex",
      name: "Dest Plate",
      formatter(props: any) {
        return <>{props.row.destPlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "destWellId", name: "Dest Pos", resizable: true },
    {
      key: "transferVol",
      name: "Cell Number",
      resizable: true,
      editor: TextEditor,
    },
  ];

  const handleApplyCellNumber = (option: string, cellNumber: number) => {
    switch (option) {
      case "Apply to All":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_ALL_DEST_STAMP_CELL_NUMBER({
            transferVol: cellNumber,
          })
        );
        break;
      case "Apply to Empty":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_EMPTY_DEST_CELL_NUMBERS({
            transferVol: cellNumber,
          })
        );
        break;
      case "Apply to Selected":
    }
  };

  const setSingleCellNumber = (rows: unknown[]) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_DEST_CELL_NUMBER({
        rows,
      })
    );
  };

  return (
    <React.Fragment>
      <WellMapping
        setSingleCellNumber={setSingleCellNumber}
        columns={columns}
        worklistValues={[
          ...worklistValues.int1ToDest,
          ...worklistValues.int2ToDest,
          ...worklistValues.int3ToDest,
        ]}
        tableHeader={
          <WellMappingTableHeader
            handleApplyCellNumber={handleApplyCellNumber}
          />
        }
      />
    </React.Fragment>
  );
};
