import { TextField } from "@mui/material";
import { ButtonGroupSelector } from "features/WorklistTools/shared/components/ButtonGroupSelector";
import React, { useState } from "react";

interface WellMappingTableHeaderProps {
  handleApplyCellNumber: (option: string, cellNumber: number) => void;
}

export const WellMappingTableHeader = ({
  handleApplyCellNumber,
}: WellMappingTableHeaderProps) => {
  const [cellNumber, setCellNumber] = useState<number | undefined>();

  return (
    <React.Fragment>
      <TextField
        value={cellNumber}
        type="number"
        onChange={(e) => setCellNumber(parseInt(e.target.value))}
        size="small"
      />
      <ButtonGroupSelector
        handleApplyCellNumber={(option: string) =>
          handleApplyCellNumber!(option, cellNumber!)
        }
      />
    </React.Fragment>
  );
};