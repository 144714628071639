import { GetUserSettings } from "helpers/user-claim-helpers";
import React from "react";
import DataGrid, { EditorProps } from "react-data-grid";
import styled from "styled-components";

const StyledWellMapping = styled(DataGrid)`
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
`;
interface WellMappingColumn {
  key: string;
  name: string;
  formatter?(props: any): JSX.Element;
  resizable: boolean;
  editor?: React.ComponentType<EditorProps<unknown, unknown>> | null;
}
interface WellMappingProps {
  setSingleCellNumber?: (rows: unknown[]) => void;
  columns: WellMappingColumn[];
  worklistValues: any[];
  tableHeader?: React.ReactNode;
}

export const WellMapping = ({
  setSingleCellNumber,
  columns,
  worklistValues,
  tableHeader,
}: WellMappingProps) => {
  const theme = GetUserSettings()?.theme?.mode ?? "light";

  return (
    <React.Fragment>
      {tableHeader && <div>{tableHeader}</div>}
      <StyledWellMapping
        className={theme === "light" ? "rdg-light" : "rdg-dark"}
        columns={columns}
        rows={[...worklistValues]}
        onRowsChange={setSingleCellNumber}
      />
    </React.Fragment>
  );
};
