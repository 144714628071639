import { createSlice } from "@reduxjs/toolkit";
import undoable, { excludeAction } from "redux-undo";
import { BaseSrcIntDestPlateInfo } from "../shared/interfaces";
import { getSourcePlateInfoState } from "./state-helpers";

export interface Twelve24TCToolState {
  sourcePlateInfo: SourcePlateInfoState[];
  destPlateInfo: DestPlateInfoState;
  worklistValues: WorklistValuesState;
  deadPlateBarcode: string;
  deadPlateType: string;
}

export interface SourcePlateInfoState extends BaseSrcIntDestPlateInfo {
  operatingVol: number;
  discardSourcePlate: number;
  wellInfo: any[];
}

export interface DestPlateInfoState extends BaseSrcIntDestPlateInfo {
  operatingVol: number;
  minOperatingVol: number;
  startingVol: number;
  preprocess: boolean;
  topupVol: number;
  plateCode: string;
}

export interface WorklistWellMappingState {
  sourcePlateIndex: number;
  sourceWellId: string;
  destPlateIndex: number;
  destWellId: string;
  transferVol?: string;
}

type DestPlateKeys = keyof DestPlateInfoState;
export type DestPlateStringKeys = Extract<DestPlateKeys, string>;

export interface WorklistValuesState {
  harvestWells: WorklistWellMappingState[];
}

export interface IStampTopLeftWorklistState {
  sourceWellId: string;
  destPlateIndex: number;
  destWellId: string;
}

export const twelve24TCInitialToolState: Twelve24TCToolState = {
  sourcePlateInfo: Array.from({ length: 8 }, () => getSourcePlateInfoState()),
  destPlateInfo: {
    plateBarcode: "",
    labwareTypeCode: "",
    operatingVol: 0,
    minOperatingVol: 0,
    startingVol: 0,
    rows: [],
    cols: [],
    preprocess: false,
    plateCode: "",
    topupVol: 0,
  },

  deadPlateBarcode: "",
  deadPlateType: "",
  worklistValues: {
    harvestWells: [],
  },
};

export const Twelve24TCToolStoreSlice = createSlice({
  name: "twelve-24-tc-tool-info",
  initialState: twelve24TCInitialToolState,
  reducers: {
    RESET_STATE: (state) => {
      state.sourcePlateInfo = twelve24TCInitialToolState.sourcePlateInfo;
      state.destPlateInfo = twelve24TCInitialToolState.destPlateInfo;
      state.worklistValues = twelve24TCInitialToolState.worklistValues;
      state.deadPlateBarcode = twelve24TCInitialToolState.deadPlateBarcode;
    },
    UPLOAD_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].plateBarcode =
        action.payload.plateBarcode;
      state.sourcePlateInfo[action.payload.index].labwareTypeCode =
        action.payload.labwareTypeCode;
      state.sourcePlateInfo[action.payload.index].operatingVol =
        action.payload.operatingVol;
      state.sourcePlateInfo[action.payload.index].rows = action.payload.rows;
      state.sourcePlateInfo[action.payload.index].cols = action.payload.cols;
      state.sourcePlateInfo[action.payload.index].wellInfo =
        action.payload.wellInfo;
      state.sourcePlateInfo[action.payload.index].discardSourcePlate = -1;
    },
    SET_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo.plateBarcode = action.payload.plateBarcode;
      state.destPlateInfo.operatingVol = action.payload.operatingVol;
      state.destPlateInfo.minOperatingVol = action.payload.minOperatingVol;
      state.destPlateInfo.labwareTypeCode = action.payload.labwareTypeCode;
      state.destPlateInfo.rows = action.payload.rows;
      state.destPlateInfo.cols = action.payload.cols;
      state.destPlateInfo.topupVol = action.payload.topupVol;
    },
    CLEAR_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].plateBarcode = "";
      state.sourcePlateInfo[action.payload.index].labwareTypeCode = "";
      state.sourcePlateInfo[action.payload.index].operatingVol = 0;
      state.sourcePlateInfo[action.payload.index].rows = [];
      state.sourcePlateInfo[action.payload.index].cols = [];
      state.sourcePlateInfo[action.payload.index].wellInfo = [];
      state.sourcePlateInfo[action.payload.index].discardSourcePlate = -1;
      state.worklistValues.harvestWells =
        state.worklistValues.harvestWells.filter(
          (e) => e.sourcePlateIndex !== action.payload.index
        );
    },

    CLEAR_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo.plateBarcode = "";
      state.destPlateInfo.operatingVol = 0;
      state.destPlateInfo.startingVol = 0;
      state.destPlateInfo.labwareTypeCode = "";
      state.destPlateInfo.rows = [];
      state.destPlateInfo.cols = [];
      state.worklistValues.harvestWells =
        state.worklistValues.harvestWells.filter(
          (e) => e.sourcePlateIndex !== action.payload.index
        );
    },

    UPDATE_AUX_PLATEBARCODES: (state, action) => {
      state.deadPlateBarcode = action.payload.deadPlateBarcode;
    },
    UPDATE_DEAD_PLATEBARCODE: (state, action) => {
      state.deadPlateBarcode = action.payload.value;
    },
    UPDATE_DEAD_PLATETYPE: (state, action) => {
      state.deadPlateType = action.payload.value;
    },
    SOURCE_TO_DEST_ADDED: (state, action) => {
      state.worklistValues.harvestWells.push(...action.payload.selections);
    },
    UPDATE_DISCARD_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].discardSourcePlate =
        action.payload.value;
    },
    UPDATE_DESTINATION_PLATE_INFO: (state, action) => {
      const key = action.payload.key as DestPlateStringKeys;
      const value = action.payload.value;
      if (typeof state.destPlateInfo[key] == typeof value) {
        //@ts-ignore
        state.destPlateInfo[key] = value;
      }
    },
    UPLOAD_SOURCE_DEAD_AND_DEST_PLATE_INFO: (state, action) => {
      state.sourcePlateInfo = action.payload.sourcePlateInfo;
      state.destPlateInfo = action.payload.destPlateInfo;
      state.deadPlateBarcode = action.payload.deadPlateBarcode;
      state.deadPlateType = action.payload.deadPlateType;
    },
    UPLOAD_WORKLIST_VALUES: (state, action) => {
      const properties: string[] = ["harvestWells"];
      properties.forEach((property) => {
        const value = Reflect.get(action.payload, property);
        Reflect.set(state.worklistValues, property, value);
      });
    },

    UPLOAD_PLATES_FROM_TEMPLATE: (state, action) => {
      for (let i = 0; i < 8; i++) {
        if (i >= action.payload.sourcePlates.length) break;
        state.sourcePlateInfo[i] = action.payload.sourcePlates[i];
      }

      state.destPlateInfo = action.payload.destPlates;
      state.deadPlateType = action.payload.deadPlateType;
    },
    UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE: (state, action) => {
      state.worklistValues.harvestWells = action.payload.harvestWells;
    },
  },
});

export const Twelve24TCToolReducer = Twelve24TCToolStoreSlice.reducer;

export const Twelve24TCToolActions = Twelve24TCToolStoreSlice.actions;

export const Twelve24TCTool = undoable(Twelve24TCToolReducer, {
  filter: excludeAction([
    Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO.toString(),
    Twelve24TCToolActions.UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE.toString(),
  ]),
});
